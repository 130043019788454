// 请求工具
import axios from 'axios';
import Vue from "vue";
import router from "@/router";
// const baseURL = 'http://localhost:9091/api/';
const baseURL = 'https://java.memeplay.top/api/';
import aes from "@/utils/aes";

Vue.prototype.baseURL = baseURL;
const service = axios.create({
  baseURL: baseURL,
});

service.interceptors.request.use(
  config => {

    const Authentication = {
      "version": "1.0.0",
      "uniqueCode": "MEME_PLAY",
      "token": localStorage.getItem("token"),
      "userId": localStorage.getItem("username")
    };

    config.headers = {
      'Content-Type': 'multipart/form-data',
      'Authentication': aes.aesJiaMi(JSON.stringify(Authentication)),
      'userId': localStorage.getItem("username")
    }

    let lang = {
      zh: 'zh-cn',
      kr: 'ko-kr',
      en: 'en-us',
      tv: 'en-us',
      ja: 'en-us',
      es: 'en-us',
      ru: 'en-us'
    }

    let str = "";

    if (config.url !== 'index/upload' && config.url !== 'demo/r') {

      if (config.data) {
        config.data.lang = lang[localStorage.getItem('lang') || 'kr'];
        str = aes.aesJiaMi(JSON.stringify(config.data));
      } else {
        config.data = {
          lang: lang[localStorage.getItem('lang') || 'kr']
        };
        str = aes.aesJiaMi(JSON.stringify(config.data));
      }

      config.data = {
        data: str
      }

    }

    return config;
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {

    if (response.data.code == 401) {
      localStorage.clear();
      if (router.currentRoute.name != 'index') {
        router.push({
          name: 'index'
        })
      }
      return;
    }

    if (response.data.code == 200) {
      if (response.data.data) {
        try {
          const text = response.data.data;
          response.data.data = aes.aesJieMi(text);
          response.data.data = JSON.parse(response.data.data);
        } catch (e) {
          return response;
        }
      }
      return response;
    } else {
      return response;
    }

  }
)

const get = (url, params) => {
  return new Promise((resolve, reject) => {
    service.get(url, {
      params: params
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.data)
    })
  })
}

const post = (url, data) => {
  return new Promise((resolve, reject) => {
    service.post(url, data).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.data)
    })
  })
}

export default {
  get,
  post,
  service
}
