import apiUtils from "./index";

export default {
  login(data) {
    return apiUtils.post('user/login', data);
  },
  run(data) {
    return apiUtils.post('mining/run', data);
  },
  index(data) {
    return apiUtils.post('index/index', data);
  },
  reinvest(data) {
    return apiUtils.post('mining/reinvest', data);
  },
  detail(data) {
    return apiUtils.post('mining/detail', data);
  },
  buy(data) {
    return apiUtils.post('mining/buy', data);
  },
  user(data) {
    return apiUtils.post('user/index', data);
  },
  profile(data) {
    return apiUtils.post('user/profile', data);
  },
  getNoticeByLang(data) {
    return apiUtils.post('user/getNoticeByLang', data);
  },
  createLog(data) {
    return apiUtils.post('user/createLog', data);
  },
  balance(data) {
    return apiUtils.post('user/balance', data);
  },
  sponsorlist(data) {
    return apiUtils.post('team/sponsorlist', data);
  },
  teamAssignlist(data) {
    return apiUtils.post('team/assignlist', data);
  },
  assignTree(data) {
    return apiUtils.post('team/assignTreeAndConfig', data);
  },
  inviteTree(data) {
    return apiUtils.post('team/inviteTreeAndConfig', data);
  },
  newInviteTree(data) {
    return apiUtils.post('team/newInviteTree', data);
  },
  support(data) {
    return apiUtils.post('team/support', data);
  },
  withdraw(data) {
    return apiUtils.post('withdraw/log', data);
  },
  withdrawDetail(data) {
    return apiUtils.get('withdraw/detail', data);
  },
  config(data) {
    return apiUtils.post('withdraw/config', data);
  },
  submit(data) {
    return apiUtils.post('withdraw/submit', data);
  },
  team(data) {
    return apiUtils.post('team/lists', data);
  },
  teamConfig(data) {
    return apiUtils.post('team/config', data);
  },
  teamInvite(data) {
    return apiUtils.post('team/invite', data);
  },
  commonInit(data) {
    return apiUtils.post('common/init', data);
  },
  webview(data) {
    return apiUtils.post('common/webview', data);
  },
  rewardlog(data) {
    return apiUtils.post('mining/rewardLog', data);
  },
  miningLog(data) {
    return apiUtils.post('mining/log', data);
  },
  assign3lists(data) {
    return apiUtils.post('team/assign3lists', data);
  },
  codetoassign(data) {
    return apiUtils.post('team/codetoassign', data);
  },
  cancelassign(data) {
    return apiUtils.post('team/cancelassign', data);
  },
  userRate(data) {
    return apiUtils.post('user/rate', data);
  },
  verificationCode(data) {
    return apiUtils.post('email/verificationCode', data);
  },
  send(data) {
    return apiUtils.post('email/send', data);
  },
  bindEmail(data) {
    return apiUtils.post('email/bindEmail', data);
  },
  delEmail(data) {
    return apiUtils.post('email/delEmail', data);
  },
  miningDetails(data) {
    return apiUtils.post('team/miningDetails', data);
  },
  oneTree(data) {
    return apiUtils.post('team/oneTree', data);
  },
  threeAssignTree(data){
    return apiUtils.post('team/threeAssignTree', data);
  },
  threeInviteTree(data){
    return apiUtils.post('team/threeInviteTree', data);
  },
  buyCall(data) {
    return apiUtils.post('mining/buyCall', data);
  },
  getBuyResult(data) {
    return apiUtils.post('mining/getBuyResult', data);
  },
  setHash(data) {
    return apiUtils.post('mining/setHash', data);
  },
  getVersionApp(data) {
    return apiUtils.post('pay/getVersionApp', data);
  },
  getWithdrawStatus(data) {
    return apiUtils.get("withdraw/getWithdrawStatus", data)
  },
  getEmailRecord(data) {
    return apiUtils.post("email/getEmailRecord", data)
  },
  setLoginPass(data) {
    return apiUtils.post('user/setLoginPass', data);
  },
  getGoogleKey(data) {
    return apiUtils.post('email/getGoogleKey', data);
  },
  upload(data) {
    return apiUtils.post('index/upload', data);
  },
  feedback(data) {
    return apiUtils.post('user/feedback', data);
  },
  getAddress() {
    return apiUtils.get("user/getAddress")
  },
  r(data) {
    return apiUtils.post("demo/r", data)
  },
  getSubUser(data) {
    return apiUtils.get("mining/getSubUser", data)
  },
  getLevelRecord(data) {
    return apiUtils.get("user/getLevelRecord", data)
  },
  getAgreement(data) {
    return apiUtils.get("index/getAgreement", data)
  },
  addAgreement(data) {
    return apiUtils.post("index/addAgreement", data)
  },
  getUsdtConfig(data) {
    return apiUtils.get("withdraw/getUsdtConfig", data)
  },
  withdrawU(data){
    return apiUtils.post("withdraw/withdrawU", data)
  }
}
